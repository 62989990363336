* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100vh;
  align-items: center;
}

.squatch-head {
  width: 500px;
  padding-bottom: 30px;
  padding-top: 30px;
  -webkit-filter: drop-shadow(10px 10px 10px #222);
  filter: drop-shadow(10px 10px 10px #222);
}

.coming-soon {
  font-size: 50px;
}

.twitch {
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-size: 100px;
  text-shadow: 10px 10px 10px #222;
}

.twitch:hover {
  transition: 0.5s;
  text-decoration: underline;
}
